@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');


h1, h2, h3 {
  font-weight: lighter;
  font-family: 'roboto', sans-serif;
}

.portfolio-card {
  height: auto;
  width: 70%;
  margin: 100px auto;

}

.img-portfolio {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.technoul {
  padding: 0px;
  margin: 0;
}

.techno {
  font-size: 20px;
  text-align: left;
  list-style-type: "\2605";
  padding: 0 10px;
  margin: auto 20px;
}

.techno0 {
  display: none;
}

.img-portfolio:hover {
  transform: scale(1.05);
  transition: 0.8s;
}

.text-portfolio {
  color: white;
  font-size: 20px;
  text-align: justify;
}

.text-portfolio-date {
  color: white;
  font-size: 20px;
  text-align: justify;
  font-style: italic;
}

.text-portfolio2 {
  color: white;
  font-size: 20px;
  text-align: left ;
}

a.linkgithub {
  width: 100px;
  white-space: nowrap;
}

.text-portfoliofront {
  color: white;
  font-size: 20px;
  text-align: center;
}

.titre-portfolio {
  width: 300px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 60px auto;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

@media screen and (max-width: 600px) {
 .text-portfolio {
   font-size: 15px;
 } 
 .text-portfolio2 {
  color: white;
  font-size: 15px;
  text-align: left ;
}

  .text-portfolio-date {
    font-size: 15px;
  }

.techno {
  font-size: 15px;
  text-align: left;
}
.technoul {
  padding-left: 10px;
}


 .text-portfoliofront {
  font-size: 15px;
} 

 .titre-portfolio {
   font-size: 25px;
 }
}