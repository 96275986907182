@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.burger {
  max-width: 100vw;
}



#menuToggle {
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  width: auto;
  margin: 20px auto 0 40px;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2; 
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: white;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}


#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: white;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}


#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}


#menu {
  position: absolute;
  width: 100%;
  height: 100vh;
  margin: -100px 0 0 -50px;
  padding-top: 200px;
  padding-left: -40px;
  background: radial-gradient(#243B55,#141E30);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(0, -100%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 25px;
  margin: 0 auto;
  color: white;
  opacity: 1;
  font-family: 'Roboto', sans-serif;
  width: 200px;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: red;
}

#menu li:hover:nth-child(even) {
  color: red;
  transform: scale(1.1);
  transform: rotate(10deg);
}
#menu li:hover:nth-child(odd) {
  color: red;
  transform: scale(1.1);
  transform: rotate(-10deg);
}

#menuToggle input:checked ~ ul {
  transform: none;
}




@media screen and (max-width:800px) {
  #menu li {
    margin: 0 auto 0 20px;
    text-align: left;
  }
}
  