@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');


.butonCookie {
  background: #11ffee00;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}

.btnRefuse {
  background: #11ffee00;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cookie {
  width: 300px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #424854;
}

.text-cookie {
  height: 60px;
  padding: 10px;
  margin: 10px auto 0 auto;
  color: white;
}

.cookies {
  margin-top: 80px;
  margin-left: 20px;
}

.divbuton {
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  width: 200px;
  justify-content: space-around;
}



.button-accueil{
border: 2px solid white;
background: transparent;
font-family: 'Roboto', sans-serif;
color: white;
width: 250px;
height: 50px;
font-size: 2em;
border-radius: 10px;
margin-top: 50px;
transition: .3s;
}

.button-accueil:hover{
border: 2px solid #19273C;
background-color: white;
cursor: pointer;
color: #19273C;
transition: .3s;
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

@media screen and (max-width:600px) {
  .button-accueil {
    font-size: 20px;
    width: 200px;
height: 40px;
  }
  .cookie {
    width: 200px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background: #424854;
  }
  .cookies {
    height: 100px;
    margin-top: 180px;
    margin-left: 20px;
  }
  .butonCookie {
    background: #11ffee00;
    border: 1px solid white;
    color: white;
    height: 20px;
    width: 60px;
    font-size: 10px;
    border-radius: 5px;
    margin: 0;
  }
  
  .btnRefuse {
    background: #11ffee00;
    border: 1px solid white;
    color: white;
    font-size: 10px;
    height: 20px;
    width: 60px;
    border-radius: 5px;
    margin: 0;
  }
  .text-cookie {
    font-size: 10px;
    padding: 20px;
    height: 25px;
  }
  .divbuton {
    height: 25px;
    padding-bottom: 10px;
  }
}

/**
 * ----------------------------------------
 * faisceau lumineux arrière plan
 * ----------------------------------------
 */


.light {
  position: absolute;
  width: 0px;
  opacity: .75;
  background-color: #7B8999;
  box-shadow: #7B8999 0px 0px 20px 2px;
  opacity: 0;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.x1{
-webkit-animation: floatUp 4s infinite linear;
-moz-animation: floatUp 4s infinite linear;
-o-animation: floatUp 4s infinite linear;
animation: floatUp 4s infinite linear;
 -webkit-transform: scale(1.0);
 -moz-transform: scale(1.0);
 -o-transform: scale(1.0);
transform: scale(1.0);
}

.x2{
-webkit-animation: floatUp 7s infinite linear;
-moz-animation: floatUp 7s infinite linear;
-o-animation: floatUp 7s infinite linear;
animation: floatUp 7s infinite linear;
-webkit-transform: scale(1.6);
-moz-transform: scale(1.6);
-o-transform: scale(1.6);
transform: scale(1.6);
left: 15%;
}

.x3{
-webkit-animation: floatUp 2.5s infinite linear;
-moz-animation: floatUp 2.5s infinite linear;
-o-animation: floatUp 2.5s infinite linear;
animation: floatUp 2.5s infinite linear;
-webkit-transform: scale(.5);
-moz-transform: scale(.5);
-o-transform: scale(.5);
transform: scale(.5);
left: -15%;
}

.x4{
-webkit-animation: floatUp 4.5s infinite linear;
-moz-animation: floatUp 4.5s infinite linear;
-o-animation: floatUp 4.5s infinite linear;
animation: floatUp 4.5s infinite linear;
-webkit-transform: scale(1.2);
-moz-transform: scale(1.2);
-o-transform: scale(1.2);
transform: scale(1.2);
left: -34%;
}

.x5{
-webkit-animation: floatUp 8s infinite linear;
-moz-animation: floatUp 8s infinite linear;
-o-animation: floatUp 8s infinite linear;
animation: floatUp 8s infinite linear;
-webkit-transform: scale(2.2);
-moz-transform: scale(2.2);
-o-transform: scale(2.2);
transform: scale(2.2);
left: -57%;
}

.x6{
-webkit-animation: floatUp 3s infinite linear;
-moz-animation: floatUp 3s infinite linear;
-o-animation: floatUp 3s infinite linear;
animation: floatUp 3s infinite linear;
-webkit-transform: scale(.8);
-moz-transform: scale(.8);
-o-transform: scale(.8);
transform: scale(.8);
left: -81%;
}

.x7{
-webkit-animation: floatUp 5.3s infinite linear;
-moz-animation: floatUp 5.3s infinite linear;
-o-animation: floatUp 5.3s infinite linear;
animation: floatUp 5.3s infinite linear;
-webkit-transform: scale(3.2);
-moz-transform: scale(3.2);
-o-transform: scale(3.2);
transform: scale(3.2);
left: 37%;
}

.x8{
-webkit-animation: floatUp 4.7s infinite linear;
-moz-animation: floatUp 4.7s infinite linear;
-o-animation: floatUp 4.7s infinite linear;
animation: floatUp 4.7s infinite linear;
-webkit-transform: scale(1.7);
-moz-transform: scale(1.7);
-o-transform: scale(1.7);
transform: scale(1.7);
left: 62%;
}

.x9{
-webkit-animation: floatUp 4.1s infinite linear;
-moz-animation: floatUp 4.1s infinite linear;
-o-animation: floatUp 4.1s infinite linear;
animation: floatUp 4.1s infinite linear;
-webkit-transform: scale(0.9);
-moz-transform: scale(0.9);
-o-transform: scale(0.9);
transform: scale(0.9);
left: 85%;
}

button:focus{
outline: none;
}

@-webkit-keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}
@-moz-keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}
@-o-keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}
@keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}

/**
 * ----------------------------------------
 * texte
 * ----------------------------------------
 */

.header{
transform: translate(-50%, -50%);
font-family: 'Roboto', sans-serif;
font-weight: 200;
color: white;
font-size: 2em;
}

#head1{
  margin-top: 25vh;
  top: 40%;
  left: 50%;
} 
#head2{
  top: 50%;
  left: 50%;
}


/**
 * ----------------------------------------
 * animation texte
 * ----------------------------------------
 */

.tracking-in-expand-fwd {
	-webkit-animation: tracking-in-expand-fwd 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand-fwd 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}



/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}


.tracking-in-expand-fwd2 {
	-webkit-animation: tracking-in-expand-fwd2 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 2s both;
	        animation: tracking-in-expand-fwd2 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 2s both;
}

@-webkit-keyframes tracking-in-expand-fwd2 {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd2 {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * @animation bouton
 * ----------------------------------------
 */

 .text-focus-in {
	-webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 4s both;
	        animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 4s both;
}

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
 @-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}


@media screen and (max-width: 600px) {
  .header{
    font-size: 20px;
    }
    

    button{
      border: 1.5px solid white;
      background: transparent;
      font-family: 'Roboto', sans-serif;
      color: white;
      width: 250px;
      height: 50px;
      font-size: 20px;
      border-radius: 10px;
      margin-top: 50px;
      transition: .3s;
      }
}