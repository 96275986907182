@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.App {
  text-align: center;
  height: auto;
}

body {
  margin: 0;
  font-weight: 100;
  background: radial-gradient(#243B55,#141E30);
  font-family: 'Roboto', sans-serif;
}

