@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');


h1, h2 {
  font-weight: lighter;
  font-family: 'roboto', sans-serif;
}

.about {
  width: 100%;
  height: auto;
}

.about-haut {
  height: auto;
}

.about-titre {
  width: 200px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 60px auto;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.softskills {
  width: 200px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 10px auto 100px auto;
  border-bottom: 1px solid white;
}

.softskills2 {
  width: 200px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 160px auto 100px auto;
  border-bottom: 1px solid white;
}

.softskills3 {
  width: 300px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 160px auto 100px auto;
  border-bottom: 1px solid white;
}



.cercle {
  margin: 100px auto 0 auto;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 75px;
}

.logo-cercle {
  width: 60px;
  height: auto;
  display: flex;
  margin: 0 auto;
  padding-top: 20px;
}

.skills {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-template-rows: repeat(2, 200px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 100px auto 0 auto;
  text-align: center;
  width: 90%;
  justify-items: center;
  justify-content: space-around;
}

.circleprogress {
  display: flex;
  justify-content: space-around;
}

.skill-percent {
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-template-rows: repeat(4, 200px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 0 auto;
  text-align: center;
  width: 90%;
  justify-items: center;
  justify-content: space-around;
  grid-column-start: span 3;
  }
  


.text-skill {
  color: white;
  font-weight: bold;
}

.About-middle {
  height: 100vh;
}

.skills-card {
  width: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
}

.photo-descri {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  height: 800px;
  width: 100%;
}

.presentation {
  color: white;
  width: 400px;
  font-size: 20px;
  text-align: justify;
  margin-left: 50px;
}

.logo-reseau {
  width: 30px;
  margin-right: 20px;
}
.logo-reseau1 {
  width: 30px;
  margin-left: 20px;
  border-radius: 5px;
}
.reseau {
  display: flex;  
  justify-content: center;
}

.link-reseau:hover {
  transform: scale(1.2);
}

.parallax {
  width: 600px;
  height: 600px;

}

@media screen and (max-width:1200px) {
  .photo-descri {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto 40px auto;
  }
  .presentation {
    margin-top: 100px;
  }

  .skills-card {
    margin: 30px auto;
  }
  .skill-percent {
    grid-template-columns: repeat(2, 300px);
  grid-template-rows: repeat(3, 200px);
  }

}



@media screen and (max-width:900px) {
  .about-haut {
    height: auto;
  }
  .about-bas {
    text-align: center;
  }

  .about-middle {
    height: auto;
  }
  .parallax {
    width: 400px;
    height: 400px;
  }
  .presentation {
    margin: 100px auto;
  }
  .skill-percent {
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(4, 200px);
  
    }

  .skills {
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(3, 200px);
  }
}

@media screen and (max-width:600px) {
  .skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  .softskills {
    margin: 10px auto;
  }

  .skill-percent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .skills-card {
    margin: 30px auto;
  }
  .parallax {
    width: 90%;
  }
  .presentation {
    margin: 100px auto;
    width: 90%;
  }
  
}
  
