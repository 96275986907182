@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
  height: auto;
}

body {
  margin: 0;
  font-weight: 100;
  background: radial-gradient(#243B55,#141E30);
  font-family: 'Roboto', sans-serif;
}


.butonCookie {
  background: #11ffee00;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}

.btnRefuse {
  background: #11ffee00;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cookie {
  width: 300px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #424854;
}

.text-cookie {
  height: 60px;
  padding: 10px;
  margin: 10px auto 0 auto;
  color: white;
}

.cookies {
  margin-top: 80px;
  margin-left: 20px;
}

.divbuton {
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  width: 200px;
  justify-content: space-around;
}



.button-accueil{
border: 2px solid white;
background: transparent;
font-family: 'Roboto', sans-serif;
color: white;
width: 250px;
height: 50px;
font-size: 2em;
border-radius: 10px;
margin-top: 50px;
transition: .3s;
}

.button-accueil:hover{
border: 2px solid #19273C;
background-color: white;
cursor: pointer;
color: #19273C;
transition: .3s;
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

@media screen and (max-width:600px) {
  .button-accueil {
    font-size: 20px;
    width: 200px;
height: 40px;
  }
  .cookie {
    width: 200px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background: #424854;
  }
  .cookies {
    height: 100px;
    margin-top: 180px;
    margin-left: 20px;
  }
  .butonCookie {
    background: #11ffee00;
    border: 1px solid white;
    color: white;
    height: 20px;
    width: 60px;
    font-size: 10px;
    border-radius: 5px;
    margin: 0;
  }
  
  .btnRefuse {
    background: #11ffee00;
    border: 1px solid white;
    color: white;
    font-size: 10px;
    height: 20px;
    width: 60px;
    border-radius: 5px;
    margin: 0;
  }
  .text-cookie {
    font-size: 10px;
    padding: 20px;
    height: 25px;
  }
  .divbuton {
    height: 25px;
    padding-bottom: 10px;
  }
}

/**
 * ----------------------------------------
 * faisceau lumineux arrière plan
 * ----------------------------------------
 */


.light {
  position: absolute;
  width: 0px;
  opacity: .75;
  background-color: #7B8999;
  box-shadow: #7B8999 0px 0px 20px 2px;
  opacity: 0;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.x1{
-webkit-animation: floatUp 4s infinite linear;
animation: floatUp 4s infinite linear;
 -webkit-transform: scale(1.0);
transform: scale(1.0);
}

.x2{
-webkit-animation: floatUp 7s infinite linear;
animation: floatUp 7s infinite linear;
-webkit-transform: scale(1.6);
transform: scale(1.6);
left: 15%;
}

.x3{
-webkit-animation: floatUp 2.5s infinite linear;
animation: floatUp 2.5s infinite linear;
-webkit-transform: scale(.5);
transform: scale(.5);
left: -15%;
}

.x4{
-webkit-animation: floatUp 4.5s infinite linear;
animation: floatUp 4.5s infinite linear;
-webkit-transform: scale(1.2);
transform: scale(1.2);
left: -34%;
}

.x5{
-webkit-animation: floatUp 8s infinite linear;
animation: floatUp 8s infinite linear;
-webkit-transform: scale(2.2);
transform: scale(2.2);
left: -57%;
}

.x6{
-webkit-animation: floatUp 3s infinite linear;
animation: floatUp 3s infinite linear;
-webkit-transform: scale(.8);
transform: scale(.8);
left: -81%;
}

.x7{
-webkit-animation: floatUp 5.3s infinite linear;
animation: floatUp 5.3s infinite linear;
-webkit-transform: scale(3.2);
transform: scale(3.2);
left: 37%;
}

.x8{
-webkit-animation: floatUp 4.7s infinite linear;
animation: floatUp 4.7s infinite linear;
-webkit-transform: scale(1.7);
transform: scale(1.7);
left: 62%;
}

.x9{
-webkit-animation: floatUp 4.1s infinite linear;
animation: floatUp 4.1s infinite linear;
-webkit-transform: scale(0.9);
transform: scale(0.9);
left: 85%;
}

button:focus{
outline: none;
}

@-webkit-keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}
@keyframes floatUp{
0%{top: 100vh; opacity: 0;}
25%{opacity: 1;}
50%{top: 0vh; opacity: .8;}
75%{opacity: 1;}
100%{top: -100vh; opacity: 0;}
}

/**
 * ----------------------------------------
 * texte
 * ----------------------------------------
 */

.header{
-webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
font-family: 'Roboto', sans-serif;
font-weight: 200;
color: white;
font-size: 2em;
}

#head1{
  margin-top: 25vh;
  top: 40%;
  left: 50%;
} 
#head2{
  top: 50%;
  left: 50%;
}


/**
 * ----------------------------------------
 * animation texte
 * ----------------------------------------
 */

.tracking-in-expand-fwd {
	-webkit-animation: tracking-in-expand-fwd 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand-fwd 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}



/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}


.tracking-in-expand-fwd2 {
	-webkit-animation: tracking-in-expand-fwd2 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 2s both;
	        animation: tracking-in-expand-fwd2 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 2s both;
}

@-webkit-keyframes tracking-in-expand-fwd2 {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd2 {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * @animation bouton
 * ----------------------------------------
 */

 .text-focus-in {
	-webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 4s both;
	        animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 4s both;
}

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
 @-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}


@media screen and (max-width: 600px) {
  .header{
    font-size: 20px;
    }
    

    button{
      border: 1.5px solid white;
      background: transparent;
      font-family: 'Roboto', sans-serif;
      color: white;
      width: 250px;
      height: 50px;
      font-size: 20px;
      border-radius: 10px;
      margin-top: 50px;
      transition: .3s;
      }
}
h1, h2 {
  font-weight: lighter;
  font-family: 'roboto', sans-serif;
}

.about {
  width: 100%;
  height: auto;
}

.about-haut {
  height: auto;
}

.about-titre {
  width: 200px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 60px auto;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.softskills {
  width: 200px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 10px auto 100px auto;
  border-bottom: 1px solid white;
}

.softskills2 {
  width: 200px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 160px auto 100px auto;
  border-bottom: 1px solid white;
}

.softskills3 {
  width: 300px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 160px auto 100px auto;
  border-bottom: 1px solid white;
}



.cercle {
  margin: 100px auto 0 auto;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 75px;
}

.logo-cercle {
  width: 60px;
  height: auto;
  display: flex;
  margin: 0 auto;
  padding-top: 20px;
}

.skills {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-template-rows: repeat(2, 200px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 100px auto 0 auto;
  text-align: center;
  width: 90%;
  justify-items: center;
  justify-content: space-around;
}

.circleprogress {
  display: flex;
  justify-content: space-around;
}

.skill-percent {
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-template-rows: repeat(4, 200px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 0 auto;
  text-align: center;
  width: 90%;
  justify-items: center;
  justify-content: space-around;
  grid-column-start: span 3;
  }
  


.text-skill {
  color: white;
  font-weight: bold;
}

.About-middle {
  height: 100vh;
}

.skills-card {
  width: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
}

.photo-descri {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  height: 800px;
  width: 100%;
}

.presentation {
  color: white;
  width: 400px;
  font-size: 20px;
  text-align: justify;
  margin-left: 50px;
}

.logo-reseau {
  width: 30px;
  margin-right: 20px;
}
.logo-reseau1 {
  width: 30px;
  margin-left: 20px;
  border-radius: 5px;
}
.reseau {
  display: flex;  
  justify-content: center;
}

.link-reseau:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.parallax {
  width: 600px;
  height: 600px;

}

@media screen and (max-width:1200px) {
  .photo-descri {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto 40px auto;
  }
  .presentation {
    margin-top: 100px;
  }

  .skills-card {
    margin: 30px auto;
  }
  .skill-percent {
    grid-template-columns: repeat(2, 300px);
  grid-template-rows: repeat(3, 200px);
  }

}



@media screen and (max-width:900px) {
  .about-haut {
    height: auto;
  }
  .about-bas {
    text-align: center;
  }

  .about-middle {
    height: auto;
  }
  .parallax {
    width: 400px;
    height: 400px;
  }
  .presentation {
    margin: 100px auto;
  }
  .skill-percent {
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(4, 200px);
  
    }

  .skills {
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(3, 200px);
  }
}

@media screen and (max-width:600px) {
  .skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  .softskills {
    margin: 10px auto;
  }

  .skill-percent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .skills-card {
    margin: 30px auto;
  }
  .parallax {
    width: 90%;
  }
  .presentation {
    margin: 100px auto;
    width: 90%;
  }
  
}
  

.contact {
  height: 100vh;
  width: 100%;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  color: white;
  width: 80%;
  font-size: 25px;
  margin: 100px auto 0 auto;
}


#phone, #nom, #prenom {
  height: 30px;
  border-radius: 10px;
  border: none;
  margin: 20px;
}

#email {
  height: 30px;
  border: none;
  border-radius: 10px;
  margin: 20px;
}

#message {
  height: 100px;
  border: none;
  border-radius: 10px;
  margin: 20px;
}

.button-contact {
border: 2px solid white;
background: transparent;
font-family: 'Roboto', sans-serif;
color: white;
width: 200px;
height: 40px;
font-size: 25px;
border-radius: 10px;
margin: 50px auto;
transition: .3s;
}

.button-contact:hover {
border: 2px solid #19273C;
background-color: white;
cursor: pointer;
color: #19273C;
transition: .3s;
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.phone {
  color: white;
  font-size: 20px;
  margin-bottom: 20px;
}

.thanks {
  color: white;
  font-size: 25px;
  margin: 100px auto 10px auto;
}

.thankyou {
  height: 100vh;
  width: 100vw;
}

input, textarea {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  padding: 5px;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: italic;
}

.copy {
  margin-right: 30px;
  color: white;
}

@media screen and (max-width:600px) {
  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-style: italic;
    margin-bottom: 10px;
  }
  .copy {
    margin: 0 auto;
  }
}
.polygon-component {
  width: 200px;
}



.polygon {
  height: 100px;
}

.polygon-descri {
  color: white;
  font-size: 20px;
  font-style: italic;
}

@media screen and (max-width: 600px) {
  .polygon-component {
    margin: 10px auto;
  }
}
  
.scroll-to-top {
  margin: 30px;
}

.scroll-to-top:hover {
  cursor: pointer;
}

.vibrate-1 {
	-webkit-animation: vibrate-1 0.5s linear infinite both;
	        animation: vibrate-1 0.5s linear infinite both;
}

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
 @-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

.burger {
  max-width: 100vw;
}



#menuToggle {
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  width: auto;
  margin: 20px auto 0 40px;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2; 
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: white;
  border-radius: 3px;
  z-index: 1;
  -webkit-transform-origin: 4px 0px;
          transform-origin: 4px 0px;
  transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
    -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
    -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menuToggle span:first-child {
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}


#menuToggle input:checked ~ span {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  background: white;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
}


#menuToggle input:checked ~ span:nth-last-child(2) {
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}


#menu {
  position: absolute;
  width: 100%;
  height: 100vh;
  margin: -100px 0 0 -50px;
  padding-top: 200px;
  padding-left: -40px;
  background: radial-gradient(#243B55,#141E30);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%);
  transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 25px;
  margin: 0 auto;
  color: white;
  opacity: 1;
  font-family: 'Roboto', sans-serif;
  width: 200px;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: red;
}

#menu li:hover:nth-child(even) {
  color: red;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
}
#menu li:hover:nth-child(odd) {
  color: red;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}

#menuToggle input:checked ~ ul {
  -webkit-transform: none;
          transform: none;
}




@media screen and (max-width:800px) {
  #menu li {
    margin: 0 auto 0 20px;
    text-align: left;
  }
}
  


.curriculum {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  width: 80%;
  flex-wrap: wrap;
  margin: 0 auto;
} 

.titre-cv {
  width: 500px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 60px auto;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.titre-cv2 {
  width: 300px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 60px auto;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.card {
  color: #D0D2D6;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 700px;
  justify-content: center;
}

.card2 {
  color: #D0D2D6;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 750px;
  margin-top: 50px;
  justify-content: center;
}
.separate {
  margin: 0 auto;
  width: 600px;
  border-top: 1px dotted #D0D2D6;
  background-color: #D0D2D6 ;
}

.trait {
  width: 300px;
  display: flex;
  margin-top: 10px;
}
.trait2 {
  width: 300px;
  display: flex;
  margin-top: 10px;
}

.imgcard {
  width: 300px;
  height: auto;
}


.cv-titre {
  font-size: 35px;
  color: #D0D2D6;
  font-weight: bold;
}

.cv-date {
  font-size: 30px;
  width: 300px;
  margin: 20px auto;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
  text-align: center;
  margin: 10px auto;
  width: 90%;
  margin : 0 auto;
  color: white;
  font-size: 25px;
}

.front-titre {
  text-align: center;
  width: 100%;
  font-size: 25px;
  font-style: italic;
}

@media screen and (max-width: 600px) {
  .card {
    width: 300px;
    margin: 0 auto;
  }
  .card2 {
    width: 300px;
    margin: 0 auto;
  }

  li {
    font-size: 15px;
  }

  .separate {
    width: 300px;
  }

  .titre-cv2 {
    margin: 60px auto 0 auto;
  }
  .imgcard {
    width: 200px;
    height: auto;
  }
  
}
h1, h2, h3 {
  font-weight: lighter;
  font-family: 'roboto', sans-serif;
}

.portfolio-card {
  height: auto;
  width: 70%;
  margin: 100px auto;

}

.img-portfolio {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.technoul {
  padding: 0px;
  margin: 0;
}

.techno {
  font-size: 20px;
  text-align: left;
  list-style-type: "\2605";
  padding: 0 10px;
  margin: auto 20px;
}

.techno0 {
  display: none;
}

.img-portfolio:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: 0.8s;
}

.text-portfolio {
  color: white;
  font-size: 20px;
  text-align: justify;
}

.text-portfolio-date {
  color: white;
  font-size: 20px;
  text-align: justify;
  font-style: italic;
}

.text-portfolio2 {
  color: white;
  font-size: 20px;
  text-align: left ;
}

a.linkgithub {
  width: 100px;
  white-space: nowrap;
}

.text-portfoliofront {
  color: white;
  font-size: 20px;
  text-align: center;
}

.titre-portfolio {
  width: 300px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 60px auto;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

@media screen and (max-width: 600px) {
 .text-portfolio {
   font-size: 15px;
 } 
 .text-portfolio2 {
  color: white;
  font-size: 15px;
  text-align: left ;
}

  .text-portfolio-date {
    font-size: 15px;
  }

.techno {
  font-size: 15px;
  text-align: left;
}
.technoul {
  padding-left: 10px;
}


 .text-portfoliofront {
  font-size: 15px;
} 

 .titre-portfolio {
   font-size: 25px;
 }
}

.div404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.flex404 {
  display: flex;
  align-items: center;
  margin-top: 6rem;
}

.title404 {
  color: white;
  padding: 0 auto;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 60px;
}
.image404 {
  height: 100px;
  margin: 0 20px;
}
.title2404 {
  color: white;
  padding: 2rem auto 0 auto;
  margin-bottom: 5rem;
  margin-top: 5rem;
  font-size: 15px;
  text-align: center;

}

.rotate-scale-up {
	-webkit-animation: rotate-scale-up 5s linear both;
	        animation: rotate-scale-up 5s linear both;
}

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(1.2) rotateZ(180deg);
            transform: scale(1.2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(1.2) rotateZ(180deg);
            transform: scale(1.2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
  }
}

@media screen and (min-width: 600px) {
  .title404 {
    font-size: 200px;
  }
  .image404 {
    height: 250px;
    margin: 0 20px;
  }
  .title2404 {
      font-size: 30px;

  }
  
}


.titre-services {
  font-weight: lighter;
  font-family: 'roboto', sans-serif;
  width: 200px;
  color: White;
  font-size: 30px;
  text-align: center;
  margin: 60px auto 130px auto;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.sous-titre-services {
  width: 90%;
  color: white;
  text-align: start;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  font-style: italic;

}

.tab-services {
  margin-top: 20px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction:row;
  border-bottom: 1px solid white;
  padding-top : 20px;
  padding-bottom : 20px;
}

.listing {
  width: 500px;
  text-align: start;
  padding: 10px;
  
}

li {
  text-align:start;
  font-size: 22px;
}

.projection {
  width: 100px;
  height: 100px;
  margin-right: 20%;
  padding-bottom:70px;
}



@media screen and (max-width:900px) {
  .sous-titre-services {
    text-align:center;
  }
  .listing {
    width: auto;
  }
  .tab-services {
    flex-direction: column;
  }
  li {
    font-size: 20px;
    text-align : center;
  }
  .projection {
    margin-top: 30px;
    margin-right:auto;
    margin-left:auto;
  }
}
