@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');


.contact {
  height: 100vh;
  width: 100%;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  color: white;
  width: 80%;
  font-size: 25px;
  margin: 100px auto 0 auto;
}


#phone, #nom, #prenom {
  height: 30px;
  border-radius: 10px;
  border: none;
  margin: 20px;
}

#email {
  height: 30px;
  border: none;
  border-radius: 10px;
  margin: 20px;
}

#message {
  height: 100px;
  border: none;
  border-radius: 10px;
  margin: 20px;
}

.button-contact {
border: 2px solid white;
background: transparent;
font-family: 'Roboto', sans-serif;
color: white;
width: 200px;
height: 40px;
font-size: 25px;
border-radius: 10px;
margin: 50px auto;
transition: .3s;
}

.button-contact:hover {
border: 2px solid #19273C;
background-color: white;
cursor: pointer;
color: #19273C;
transition: .3s;
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.phone {
  color: white;
  font-size: 20px;
  margin-bottom: 20px;
}

.thanks {
  color: white;
  font-size: 25px;
  margin: 100px auto 10px auto;
}

.thankyou {
  height: 100vh;
  width: 100vw;
}

input, textarea {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  padding: 5px;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: italic;
}

.copy {
  margin-right: 30px;
  color: white;
}

@media screen and (max-width:600px) {
  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-style: italic;
    margin-bottom: 10px;
  }
  .copy {
    margin: 0 auto;
  }
}